<template>
	<div class="hello">
		<swiper :options="swiperOption" ref="mySwiper" v-if="bannerList.length > 0">
			<!-- slides -->
			<!-- <swiper-slide>
                
            </swiper-slide> -->
			<swiper-slide class="swiper_sd" v-for="(item, index) in bannerList" :key="index">
				<div @click="jumpBannerFun(item.url, item)">
					<img :src="item.thumb" alt="" />
					<div class="swiper_item_img swiper_item_img_l" v-if="item.thumb_left">
						<img :src="item.thumb_left" alt="" />
					</div>
					<div class="swiper_item_img swiper_item_img_r" v-if="item.thumb_right">
						<img :src="item.thumb_right" alt="" />
					</div>
				</div>
			</swiper-slide>
			<!-- Optional controls -->
			<div class="swiper-pagination" slot="pagination"></div>
			<div class="swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-button-next" slot="button-next"></div>
		</swiper>
		<div class="mian">
			<!-- 搜索 -->
			<!-- wow animate__animated animate__fadeInUp -->
			<div class="index_search" ref="searchBox">
				<div class="search_select" @mouseenter="selectShow = true" @mouseleave="selectShow = false">
					<div class="search_select_box">
						<div>{{ search_name }}</div>
						<img src="../assets/images/jiantou01@2x.png" alt="" />
					</div>
					<!-- 下拉三角隐藏 -->
					<div class="search_select_list" v-show="selectShow">
						<template v-for="(item, index) in hot_words">
							<div :key="index" v-if="item.name != '变形宝典' && item.name != '每日一字'"
								@click="searchHotFun(item)">
								{{ item.name }}
							</div>
						</template>
					</div>
				</div>
				<span class="line"></span>
				<div class="search_input">
					<!-- <input type="text" v-model="keywords" @focus="isHistory = true" @blur="isHistory = false" :placeholder="history" @keyup.enter="searchFun"> -->
					<input type="text" v-model="keywords" ref="keyword" @focus="isHistory = true" @blur="blurFun"
						@keyup.enter="searchFun" />
					<!-- onkeyup="this.value=this.value.replace(/[ ]/g,'')" -->
				</div>
				<div class="index_search_button" @click="searchFun">
					<img src="../assets/images/indexsearch.png" alt="" />
				</div>
				<div class="search_history" v-show="isHistory && indexHistory.length > 0">
					<div class="search_history_list">
						最近搜索：<span class="item" v-for="(item, index) in indexHistory" :key="index"><span
								@click="(keywords = item), searchFun(item)">{{
                item
              }}</span>
							<img @click="onlyDelFun(index)" src="../assets/images/close.png" alt="" /></span>
					</div>
					<div class="search_history_clear" @click="delHistoryFun">
						<img src="../assets/images/clear.png" alt="" />
						清除
					</div>
				</div>
			</div>

			<div class="hot_search_box">
				<div class="hot_search" v-show="search_name != '变形宝典' && search_name != '每日一字'">
					<div>热搜词：</div>
					<template v-if="hot_list.length > 0">
						<div class="hot_search_li" @click="(keywords = item), searchFun()"
							v-for="(item, index) in hot_list" :key="index">
							{{ item }}
						</div>
					</template>
					<span style="color: #999" v-else>暂无</span>
				</div>
			</div>
		</div>
		<div class="mian">
			<!-- 模块入口 -->
			<div class="entrance_nav clearfix">
				<!-- wow animate__animated animate__fadeInUp -->

				<router-link to="/video" class="fl entrance_nav_li">
					<!-- <img :img="fourItem.thumb2" :key="fourItem.thumb2" alt=""> -->
					<img v-lazy="fourItem.thumb2" :key="fourItem.thumb2" alt="" />
				</router-link>
				<router-link to="/material" class="fl entrance_nav_li">
					<!-- <img :img="fourItem.thumb1" :key="fourItem.thumb1" alt=""> -->
					<img v-lazy="fourItem.thumb1" :key="fourItem.thumb1" alt="" />
				</router-link>
				<!-- <router-link to="/deformation" class="fl entrance_nav_li">
                    <img v-lazy="fourItem.thumb3" :key="fourItem.thumb3" alt="">
                </router-link> -->
				<router-link to="/dailyWord" class="fl entrance_nav_li">
					<!-- <img :img="fourItem.thumb4" :key="fourItem.thumb4" alt=""> -->
					<img v-lazy="fourItem.thumb4" :key="fourItem.thumb4" alt="" />
				</router-link>

				<!-- <router-link to="/auditionlist" class="fl entrance_nav_li"> -->
				<!-- <img :img="fourItem.thumb4" :key="fourItem.thumb4" alt=""> -->
				<!-- <img v-lazy="fourItem.thumb3" :key="fourItem.thumb3" alt="" />
				</router-link> -->
			</div>

			<!-- 视频教程 -->
			<div class="alink">
				<div id="video" class="alink_top"></div>
				<div class="public_title">
					<div class="public_title_l public_title_ls">
						<div class="public_title_name">视频教程推荐</div>
						<!-- <div class="public_title_tab clearfix">
                            <div :class="tabIndex == index? 'default':''" v-for="(item,index) in video_cate" :key="index" @click="videosFun(index,item.id)">{{item.name}}<span v-if="index!=0"></span></div>
                        </div> -->
					</div>
					<router-link to="/video" class="public_title_more">查看更多</router-link>
				</div>
				<div class="video_list clearfix gradient_position">
					<template v-if="videoList.length > 0">
						<!-- wow animate__animated animate__fadeInUp -->

						<div class="daily_ul" v-for="(row, i) in videoList" :key="i">
							<div class="video_li" v-for="(item, index) in row" :key="index" @click="videoFun(item.id)">
								<div class="video_li_box">
									<img v-lazy="item.thumb" :key="item.thumb" alt="" />
									<div class="project_li_buttom">{{ item.title }}</div>
									<div class="video_li_mask"></div>
									<div class="video_li_height">{{ item.level_name }}</div>
									<div class="video_li_collect" v-if="item.is_collect == 0"
										@click.stop="collectFun(3, item.id, index)">
										<img src="../assets/images/weishoucang.svg" alt="" />
									</div>
									<div class="video_li_collect" v-else @click.stop="uncollectFun(3, item.id, index)">
										<img src="../assets/images/icon_collection@2x.svg" alt="" />
									</div>
									<div class="video_li_play">
										<img src="../assets/images/icon_play@2x.png" alt="" />
									</div>
								</div>
							</div>
						</div>
						<div class="gradient_mask"></div>
						<div class="view_more">
							<router-link to="/video">查看更多</router-link>
						</div>
					</template>
					<el-empty v-else description="暂无数据"></el-empty>
				</div>
			</div>
			<!-- 推荐专题 -->
			<div class="alink">
				<div id="public" ref="public" class="alink_top"></div>
				<div class="public_title">
					<div class="public_title_name">素材专题推荐</div>
					<router-link to="/project" class="public_title_more">查看更多</router-link>
				</div>
				<div class="project clearfix">
					<template v-if="projectList.length > 0">
						<!-- wow animate__animated animate__fadeInUp -->
						<div class="fl project_li" v-for="(item, index) in projectList" :key="index"
							@click="toProDetailFun(item.id)">
							<div class="project_li_box">
								<img v-lazy="item.thumb" :key="item.thumb" alt="" />
								<div class="project_li_hot" v-if="item.is_hot == 1">HOT</div>
								<div class="project_li_buttom">{{ item.title }}</div>
								<div class="project_li_mask">
									<p>{{ item.title }}</p>
								</div>
								<div class="project_li_collect" v-if="item.is_collect == 0"
									@click.stop="collectFun(1, item.id, index)">
									<img src="../assets/images/weishoucang.svg" alt="" />
								</div>
								<div class="project_li_collect" v-else @click.stop="uncollectFun(1, item.id, index)">
									<img src="../assets/images/icon_collection@2x.svg" alt="" />
								</div>
							</div>
						</div>
					</template>
					<el-empty v-else description="暂无数据"></el-empty>
				</div>
			</div>
			<!-- 每日推荐 -->
			<div class="alink">
				<div id="daily" class="alink_top"></div>
				<div class="public_title">
					<div class="public_title_l">
						<div class="public_title_name">热点素材推荐</div>
						<!-- <div class="public_title_tip">根据你的喜好，每日更新</div> -->
					</div>
					<router-link to="/material" class="public_title_more">查看更多</router-link>
				</div>
				<div class="daily clearfix gradient_position">
					<template v-if="dailyList.length > 0">
						<!-- wow animate__animated animate__fadeInUp -->
						<div class="daily_ul" v-for="(row, i) in dailyList" :key="i">
							<div class="daily_li" @click="toDailyDetail(item.id)" v-for="(item, index) in row"
								:key="index">
								<div class="daily_li_box">
									<img v-lazy="item.thumb" :key="item.thumb" alt="" />
									<div class="daily_li_mask"></div>
									<div class="daily_li_downLoad" @click.stop="downFun(item.id)">
										下载
										<!-- <span></span>
                                        <img src="../assets/images/icon_mrtj_download@2x.png" alt=""> -->
									</div>
									<div class="daily_li_collect" v-if="item.is_collect == 0"
										@click.stop="collectFun(2, item.id, index)">
										<img src="../assets/images/weishoucang.svg" alt="" />
									</div>
									<div class="daily_li_collect" v-else @click.stop="uncollectFun(2, item.id, index)">
										<img src="../assets/images/icon_collection@2x.svg" alt="" />
									</div>
									<!-- <div class="daily_li_shop" @click.stop="jionFun(1,item.id)"><img src="../assets/images/icon_add@2x.svg" alt=""></div> -->
									<div class="daily_li_shop" v-if="item.is_down_cart == 0"
										@click.stop="addToShopCars($event, 1, item)">
										<img src="../assets/images/icon_add@2x.svg" alt="" />
									</div>
									<div class="daily_li_shop" v-else @click.stop="addToShopCars($event, 1, item)">
										<img src="../assets/images/addqingdan1.svg" alt="" />
									</div>
								</div>
							</div>
						</div>
						<div class="gradient_mask"></div>
						<div class="view_more">
							<router-link to="/material">查看更多</router-link>
						</div>
					</template>

					<el-empty v-else description="暂无数据"></el-empty>
				</div>
			</div>

			<!-- 广告 -->
			<div class="cbanner" @click="jumpBannerFun(banner.url, banner)">
				<img :src="banner.thumb" alt="" />
			</div>
			<!-- 母婴推荐 -->
			<div class="alink">
				<div id="industry" class="alink_top"></div>
				<template v-if="special_list.length > 0">
					<div v-for="(item, index) in special_list" :key="index">
						<div class="public_title">
							<div class="public_title_name">{{ item.title }}</div>
							<div @click="otherDetail(item.id)" class="public_title_more">
								查看更多
							</div>
						</div>
						<div class="mom_list clearfix" v-if="item.materials_list_lm.length > 0">
							<!-- wow animate__animated animate__fadeInUp -->
							<div class="daily_ul" v-for="(row, i) in item.materials_list_lm" :key="i">
								<div class="daily_li mom_li" @click="toDailyDetail(items.id)"
									v-for="(items, indexs) in row" :key="indexs">
									<div class="daily_li_box mom_li_box">
										<img v-lazy="items.thumb" :key="items.thumb" alt="" />
										<div class="daily_li_mask"></div>
										<div class="daily_li_downLoad" @click.stop="downFun(items.id)">
											下载
											<!-- <span></span>
                                            <img src="../assets/images/icon_mrtj_download@2x.png" alt=""> -->
										</div>
										<div class="daily_li_collect" v-if="items.is_collect == 0"
											@click.stop="collectFun(2, items.id, index)">
											<img src="../assets/images/weishoucang.svg" alt="" />
										</div>
										<div class="daily_li_collect" v-else
											@click.stop="uncollectFun(2, items.id, index)">
											<img src="../assets/images/icon_collection@2x.svg" alt="" />
										</div>
										<div class="daily_li_shop" v-if="items.is_down_cart == 0"
											@click.stop="addToShopCars($event, 1, items)">
											<img src="../assets/images/icon_add@2x.svg" alt="" />
										</div>
										<div class="daily_li_shop" v-else @click.stop="addToShopCars($event, 1, items)">
											<img src="../assets/images/addqingdan1.svg" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<el-empty v-else description="暂无数据"></el-empty>
					</div>
				</template>
				<el-empty v-else description="暂无数据"></el-empty>
			</div>
		</div>
		<div class="member">
			<div class="membervip mian">
				<!-- @click="toVipFun(1)" -->

				<div class="member_box" @click="toVipFun(2)"
					:style="{ backgroundImage: 'url(' + vipInfo.sp_bj_thumb + ')' }">
					<div class="member_box_top">
						<div class="member_box_top_one">
							<img :src="vipInfo.sp_thumb" alt="" />
							<!-- <h2>{{vipInfo.sp_title}}</h2>
							<p>{{vipInfo.sp_desc}}</p>
							<div class="member_box_top_one_video">定价<span>598</span>元，首发价<span>365</span>元</div> -->
						</div>
						<!-- 	<div class="member_box_top_two">
							<h2>{{vipInfo.sp_yd_title}}</h2>
							<p>{{vipInfo.sp_yd_desc}}</p>
							<h2>{{vipInfo.sp_nd_title}}</h2>
							<p>{{vipInfo.sp_nd_desc}}</p>
						</div> -->
					</div>
					<!--<div class="member_box_button" @click="toVipFun(2)"><span>成为会员</span></div>-->
				</div>
				<div class="member_box" @click="toVipFun(1)"
					:style="{ backgroundImage: 'url(' + vipInfo.sc_bj_thumb + ')' }">
					<div class="member_box_top">
						<div class="member_box_top_one">
							<img :src="vipInfo.sc_thumb" alt="" />
							<!-- 	<h2>{{vipInfo.sc_title}}</h2>
							<p>{{vipInfo.sc_desc}}</p>
							<div class="member_box_top_one_video">定价<span>198</span>元 免费赠送</div>
							<div class="member_box_top_one_video"></div>
							<div class="member_box_top_one_song">
								2022年11月1日-2023年6月1日，在此期间，购买视频会员，<span>赠送半年“素材会员”</span></div> -->
						</div>
						<!-- <div class="member_box_top_two">
							<h2>{{vipInfo.sc_yd_title}}</h2>
							<p>{{vipInfo.sc_yd_desc}}</p>
							<h2>{{vipInfo.sc_nd_title}}</h2>
							<p>{{vipInfo.sc_nd_desc}}</p>
						</div> -->
					</div>
					<!-- <div class="member_box_button"><span>成为会员</span></div> -->
				</div>
			</div>
		</div>
		<div class="mian">
			<div class="provide_list clearfix">
				<div class="fl provide_li" @click="toVipbuyFun">
					<img src="../assets/images/xtspjc.svg" alt="" />
					<h5>海量系统视频教程</h5>
					<p>不同风格字体设计视频全覆盖</p>
				</div>
				<div class="fl provide_li" @click="toVipbuyFun">
					<img src="../assets/images/zydp.svg" alt="" />
					<h5>作业100%点评</h5>
					<p>每个视频均可提交作业</p>
				</div>
				<div class="fl provide_li" @click="toVipbuyFun">
					<img src="../assets/images/sybz.svg" alt="" />
					<h5>素材商用保障</h5>
					<p>所有字体素材均可商用</p>
				</div>
				<div class="fl provide_li" @click="toVipbuyFun">
					<img src="../assets/images/hlscxz.svg" alt="" />
					<h5>海量素材下载</h5>
					<p>精选标题设计素材 行业涉略全面</p>
				</div>
				<div class="fl provide_li" @click="toVipbuyFun">
					<img src="../assets/images/sjbzzs.svg" alt="" />
					<h5>设计步骤展示</h5>
					<p>教程和字体素材源文件均保留步骤</p>
				</div>
			</div>
		</div>

		<!-- 侧边导航 -->
		<div class="sidebar sidebar_index" :style="{ left: left1 + 'px' }">
			<a class="sidebar_logo">
				<img src="../assets/images/logo.jpg" alt="" />
			</a>
			<a href="javascript:" class="sidebar_li" @click="linkFun('#video')">
				<img src="../assets/images/icon03_left@2x.png" alt="" />
				<p>视频教程</p>
			</a>
			<a href="javascript:" class="sidebar_li" @click="linkFun('#public')">
				<img src="../assets/images/icon01_left@2x.png" alt="" />
				<p>推荐专题</p>
			</a>
			<a href="javascript:" class="sidebar_li" @click="linkFun('#daily')">
				<img src="../assets/images/icon02_left@2x.png" alt="" />
				<p>素材推荐</p>
			</a>

			<a href="javascript:" class="sidebar_li" @click="linkFun('#industry')">
				<img src="../assets/images/icon04_left@2x.png" alt="" />
				<p>行业推荐</p>
			</a>
			<!-- <a href="javascript:" class="sidebar_top" @click="topBackFun()">
                <img src="../assets/images/top@2x.png" alt="">
            </a> -->
		</div>
		<div class="mask" v-if="isShow" @click="closeMsg">
			<collection isMask="1" :collectionId="collId" :type="type" @closeFun="closeFun"></collection>
		</div>
		<div class="sidebar sidebar_r" :style="{ right: right1 + 'px' }">
			<div class="sidebar_li" @click="jumpFun('downLoad', '')">
				<div class="zongj" v-if="total > 0">
					<span>{{ total > 99 ? "99" : total }}</span>
				</div>
				<img src="../assets/images/list@2x.png" alt="" />
				<p>下载清单</p>
			</div>
			<div class="sidebar_li" @click="jumpFun('faq', '')">
				<img src="../assets/images/Q@2x.png" alt="" />
				<p>常见问题</p>
			</div>
			<div class="sidebar_li" @click="contactqq">
				<img src="../assets/images/qq.png" alt="" />
				<p>联系我们</p>
			</div>

			<a href="javascript:" class="sidebar_top" @click="topBackFun()">
				<img src="../assets/images/top@2x.png" alt="" />
			</a>
		</div>
		<!-- 加入清单的动画效果7.1日隐藏 -->
		<!-- <transition
       @before-enter="beforeEnter"
       @enter="enter"
       @after-enter="afterEnter"
       >
           
           <div class="shop_car_ball" v-show="shopCarBall">
               <div class="linner_ball">
                   <img :src="addimg" alt="">
               </div>
           </div>
           
       </transition> -->
	</div>
</template>

<script>
	import collection from "../components/collection.vue";
	// import { WOW } from 'wowjs'
	import {
		Swiper,
		SwiperSlide
	} from "vue-awesome-swiper";
	import "swiper/css/swiper.css";
	import Ranking from "./ranking.vue";
	export default {
		name: "HelloWorld",
		components: {
			collection,
			Swiper,
			SwiperSlide,
		},
		props: {
			msg: String,
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = () => {
				console.log("slide change");
			};
			return {
				onSwiper,
				onSlideChange,
			};
		},
		data() {
			return {
				bannerList: [],
				search_name: "",
				keywords: "", //搜索内容
				hot_words: [], //搜索分类及关键词
				hot_list: [], //热搜词
				history_btn: false,
				fourItem: {},
				// entranceNavList:[{url:},{url:require('../assets/images/ad_banner2.png')},{url:require('../assets/images/ad_banner1.png')},{url:require('../assets/images/ad_banner1.png')}],
				projectList: [],
				dailyList: [],
				video_cate: [], //视频分类
				tabIndex: 0, //分类默认选中
				videoList: [],
				special_list: [],

				swiperOption: {
					autoplay: true,
					pagination: {
						el: ".swiper-pagination",
					},
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
					loop: true,
				},

				// 搜索选择
				selectShow: false,

				isShow: false, //收藏显示
				collId: "", //收藏显示
				type: "", //收藏显示

				loading: true,

				vipInfo: {}, //会员信息

				banner: {}, //中部广告位banner

				// 加入清单
				shopCarNum: window.localStorage.getItem("shopcarnum") || 0,
				shopCarBall: false,
				shopCarBallEl: null,
				ss: null,
				// demo测试数据
				testList: new Array(1, 2, 3, 4, 5),

				addimg: "",

				indexHistory: [],
				history: "",
				isHistory: false, //显示搜索历史

				scroll: 0, //滚动高度
				total: 0, //购物车数量
				qq: "",
			};
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.swiper;
			},
			left1() {
				var left = 0;
				if (window.innerWidth - 1400 > 128) {
					left = ((window.innerWidth - 1400) / 2 - 64) / 2;
				} else {
					left = 0;
				}
				console.log(left);
				return left;
			},
			right1() {
				var left = 0;
				if (window.innerWidth - 1400 > 128) {
					left = ((window.innerWidth - 1400) / 2 - 64) / 2;
				} else {
					left = 0;
				}
				console.log(left);
				return left;
			},
		},
		mounted() {
			if (localStorage.getItem("total") && localStorage.getItem("access_token")) {
				this.total = localStorage.getItem("total");
			} else {
				this.total = 0;
			}

			if (localStorage.getItem("indexHistory")) {
				this.indexHistory = localStorage
					.getItem("indexHistory")
					.split(",")
					.slice(0, 5);
				this.history = this.indexHistory.join(" ");
			}
			if (localStorage.getItem("access_token")) {
				this.listFun();
			}
			this.$parent.routerIndex = 1;
			// new WOW().init();
			this.bannerOneban();
			this.bannerIndex();
			this.initFun();
			// window.addEventListener("scroll", this.handleScroll);
			window.addEventListener("scroll", this.handleScroll);
			this.getqq();
			if (this.$store.state.navList) {
				this.hot_words = this.$store.state.navList.hot_words;
				if (this.hot_words && this.hot_words.length > 0) {
					this.search_name = this.hot_words[0].name;
					this.hot_list = this.hot_words[0].lists;
				}
				console.log("1111111111111", this.$store.state.navList.video_list);
				if (
					this.$store.state.navList.video_list.length > 1 &&
					this.$store.state.navList.video_list[1].list
				) {
					this.video_cate = this.$store.state.navList.video_list[1].list;
				}
			}
		},
		methods: {
			//获取清单数量
			listFun() {
				var that = this;
				this.$api.POST(this.$face.download_cartIndex, "", function(res) {
					that.total = res.data.total;
					localStorage.setItem("total", res.data.total);
				});
			},
			// 获取内部banner、
			bannerOneban() {
				var that = this;

				this.$api.POST(
					this.$face.bannerOneban, {
						location: 7,
					},
					function(res) {
						switch (res.data.links) {
							case 0:
								res.data.url = "";
								break;
							case 1:
								res.data.url = "material";
								break;
							case 2:
								res.data.url = "video";
								break;
							case 3:
								res.data.url = "deformation";
								break;
							case 4:
								res.data.url = "ranking";
								break;
							case 5:
								res.data.url = "dailyWord";
								break;
							case 6:
								res.data.url = "fontSearch";
								break;
							case 7:
								res.data.url = "dailyWordUpload";
								break;
							case 8:
								res.data.url = "vip";
								break;
							case 9:
								res.data.url = "project";
								break;
							case 10:
								res.data.url = "rankingDetail";
								break;
							case 11:
								res.data.url = "projectDetail";
								break;
							case 12:
								res.data.url = "matchIndex";
								break;
							case 13:
								res.data.url = "integralShop";
								break;
							case 14:
								res.data.url = "integralIntro";
								break;
							case 15:
								res.data.url = res.data.urls;
								break;
						}
						that.banner = res.data;
					}
				);
			},

			bannerClickFun() {
				this.$router.push({
					name: "dailyWord",
				});
			},

			bannerIndex() {
				var that = this;
				this.$api.POST(this.$face.bannerIndex, "", function(res) {
					res.data.forEach((element) => {
						switch (element.links) {
							case 0:
								element.url = "";
								break;
							case 1:
								element.url = "material";
								break;
							case 2:
								element.url = "video";
								break;
							case 3:
								element.url = "deformation";
								break;
							case 4:
								element.url = "ranking";
								break;
							case 5:
								element.url = "dailyWord";
								break;
							case 6:
								element.url = "fontSearch";
								break;
							case 7:
								element.url = "dailyWordUpload";
								break;
							case 8:
								element.url = "vip";
								break;
							case 9:
								element.url = "project";
								break;
							case 10:
								element.url = "rankingDetail";
								break;
							case 11:
								element.url = "projectDetail";
								break;
							case 12:
								element.url = "matchIndex";
								break;
							case 13:
								res.data.url = "integralShop";
								break;
							case 14:
								res.data.url = "integralIntro";
								break;
							case 15:
								res.data.url = res.data.urls;
								break;
						}
					});
					that.bannerList = res.data;
					that.$nextTick(() => {
						that.$swiper.slideTo(3, 1000, true);
					});
				});
			},

			initFun() {
				console.log(1123);
				var that = this;
				this.$api.POST(this.$face.index, "", function(res) {
					// that.bannerList = res.data.banner;
					that.projectList = res.data.special;
					that.dailyList = that.$utile.listData(res.data.everyday);
					that.videoList = that.$utile.listData(res.data.video);
					res.data.special_list.forEach((item) => {
						item.materials_list_lm = that.$utile.listData(item.materials_list_lm);
					});
					that.special_list = res.data.special_list;
					that.vipInfo = res.data.footer_info;
					that.fourItem = res.data.four_thumbs;
					setTimeout(() => (that.loading = false), 2000);
				});
			},

			// 选择搜索分类
			searchHotFun(row) {
				this.search_name = row.name;
				this.hot_list = row.lists;
				this.selectShow = false;
			},

			//搜索
			searchFun() {
				console.log(1111);
				if (this.keywords) {
					this.indexHistory.forEach((item, index) => {
						if (item == this.keywords) {
							this.indexHistory.splice(index, 1);
						}
					});
					this.keywords = this.keywords.split(" ").join("");
					this.indexHistory.unshift(this.keywords);
					localStorage.setItem("indexHistory", this.indexHistory.join(","));
				}
				if (this.search_name == "字体素材") {
					this.$router.push({
						name: "material",
						query: {
							keywords: this.keywords
						},
					});
				} else if (this.search_name == "视频教程") {
					this.$router.push({
						name: "video",
						query: {
							keywords: this.keywords
						},
					});
				}
				// this.$router.push({
				// 	name: "material",
				// 	query: {
				// 		keywords: this.keywords,
				// 	},
				// });
				// if(this.search_name == '字体素材'){
				//     this.$router.push({name:'material',query:{keywords:this.keywords}})
				// }else if(this.search_name == '变形宝典'){
				//     this.$router.push({name:'deformation',query:{keywords:this.keywords}})
				// }else{
				//     this.$router.push({name:'fontSearch',query:{keywords:this.keywords}})
				// }
			},
			//删除搜索历史
			onlyDelFun(index) {
				this.history_btn = true;
				this.indexHistory.splice(index, 1);
				localStorage.setItem("indexHistory", this.indexHistory.join(","));

				setTimeout(() => {
					this.$refs.keyword.focus();
					this.history_btn = false;
				}, 150);
			},

			//清楚搜索历史
			delHistoryFun() {
				this.history_btn == false;
				this.indexHistory = [];
				localStorage.removeItem("indexHistory");
			},

			blurFun() {
				setTimeout(() => {
					if (this.history_btn == false) {
						setTimeout(() => {
							this.isHistory = false;
						}, 200);
					}
				}, 100);
			},

			// 切换视频分类
			videosFun(index, id) {
				this.tabIndex = index;
				var that = this;
				this.$api.POST(
					this.$face.videos, {
						font_style: id,
					},
					function(res) {
						that.videoList = that.$utile.listData(res.data);
					}
				);
			},

			//收藏
			collectFun(type, id, index) {
				if (localStorage.getItem("access_token")) {
					this.type = type;
					this.collId = id;
					this.isShow = true;
				} else {
					this.$parent.loginShow();
				}
			},
			//取消收藏
			uncollectFun(type, id, index) {
				var that = this;
				var params = {
					coll_id: id,
					type: type,
				};
				this.$api.POST(this.$face.collectionColl, params, function(res) {
					that.$utile.prompt("success", "取消收藏");
					that.initFun();
				});
			},
			//关闭收藏
			closeFun() {
				this.initFun();
				this.isShow = false;
			},
			// 点击遮罩层关闭
			closeMsg(ev) {
				let dom = document.getElementById("child");
				if (dom) {
					if (!dom.contains(ev.target)) {
						this.isShow = false;
					}
				}
			},
			getqq() {
				var that = this;

				this.$api.POST(this.$face.getqq, {}, function(res) {
					that.qq = res.data.qq;
				});
			},
			contactqq() {
				//window.open('tencent://message/?Menu=yes&uin=' + this.qq + '&Site=https://new.zitisheji.com');tencent://message/?Menu=yes&uin={$footer['qq']|default=''}&Service=300&sigT=45a1e5847943b64c6ff3990f8a9e644d2b31356cb0b4ac6b24663a3c8dd0f8aa12a595b1714f9d45
				//window.open('tencent://message/?uin='+this.qq+'&Site=https://new.zitisheji.com&Menu=yes');
				window.open(
					"http://dct.zoosnet.net/LR/Chatpre.aspx?id=DCT52374225&lng=cn"
				);
			},
			//加入清单
			jionFun(type, id) {
				var that = this;
				var params = {
					material_id: id,
					type: type,
				};
				this.$api.POST(this.$face.download_cartJoin, params, function(res) {
					that.$utile.prompt("success", res.msg);
					that.initFun();
					that.listFun();
				});
			},

			// 下载源文件
			downFun(id) {
				if (localStorage.getItem("access_token")) {
					var that = this;
					var params = {
						id: id,
					};
					this.$api.POST(this.$face.downloadDown, params, function(res) {
						const a = document.createElement("a");
						//   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
						a.href = res.data.down_url; // 完整的url则直接使用
						// document.body.appendChild(a)
						a.click();
					});
				} else {
					this.$parent.loginShow();
				}
			},

			// 查看专题详情
			toProDetailFun(id) {
				this.$router.push({
					name: "projectDetail",
					query: {
						id: id,
					},
				});
			},

			// 查看素材详情
			toDailyDetail(id) {
				//   this.$router.push({
				//     name: "materialDetail",
				//     query: {
				//       id: id,
				//     },
				//   });
				let routeData = this.$router.resolve({
					name: "materialDetail",
					query: {
						id: id,
					},
				});
				window.open(routeData.href, "_blank");
			},

			// 查看视频详情
			videoFun(id) {
				//   this.$router.push({
				//     name: "videoDetail",
				//     query: {
				//       id: id,
				//     },
				//   });
				let routeData = this.$router.resolve({
					name: "videoDetail",
					query: {
						id: id,
					},
				});
				window.open(routeData.href, "_blank");
			},

			// 查看vip
			toVipFun(el) {
				//   this.$router.push({
				//     name: "vip",
				//   });
				let routeData = this.$router.resolve({
					name: "vip",
				});
				window.open(routeData.href, "_blank");
			},
			toVipbuyFun() {
				//   this.$router.push({
				//     name: "vip",
				//   });
				let routeData = this.$router.resolve({
					name: "vip",
				});
				window.open(routeData.href, "_blank");
			},
			jumpFun(url, param) {
				let routeData = this.$router.resolve({
					name: url,
					query: {
						id: param,
					},
				});
				window.open(routeData.href, "_blank");
			},
			jumpBannerFun(url, param) {
				//console.log(param.id);
				// 点击banner统计次数接口
				this.bannerrecord(param.id)
				if (param.links == 15) {
					window.open(param.urls);
					return;
				}
				if (param.festival != 0) {
					localStorage.setItem("festival", param.festival);
				}
				if (param.solar_terms != 0) {
					localStorage.setItem("solar_terms", param.solar_terms);
				}
				if (param.industry != 0) {
					localStorage.setItem("industry", param.industry);
				}

				if (param.font_cate != 0) {
					localStorage.setItem("font_cate", param.font_cate);
				}
				if (param.font_style != 0) {
					localStorage.setItem("font_style", param.font_style);
				}
				if (param.series != 0) {
					localStorage.setItem("series", param.series);
				}

				let query;
				if (param.font_cate != 0 || param.font_cate != 0 || param.series != 0) {
					query = {};
				} else {
					query = {
						id: param.links_id,
					};
				}
				let routeData = this.$router.resolve({
					name: url,
					query: query,
				});
				window.open(routeData.href, "_blank");


				// if (localStorage.getItem("access_token")) {
					
				// } else {
				//   this.$parent.loginShow();
				// }
				
			},
			// 点击banner统计次数接口
			bannerrecord(id){
				console.log('bannerid',id)
				var that = this;
				var params = {
					banner_id: id,
				};
				this.$api.POST(this.$face.bannerecord, params, function(res) {
					console.log('bannerres',res)
				});
			},
			otherDetail(id) {
				this.$router.push({
					name: "projectDetail",
					query: {
						id: id,
					},
				});
			},

			// 滚动监听
			handleScroll() {
				if (this.$route.name == "index") {
					// if(this.$refs.searchBox.getBoundingClientRect().top<20){
					//     this.$parent.showIndex = 2
					// }else{
					//     this.$parent.showIndex = 1
					// }
					this.$parent.showIndex = 1;
					if (
						(document.documentElement.scrollTop || document.body.scrollTop) > 10
					) {
						this.$parent.isNav = true;
					} else {
						this.$parent.isNav = false;
					}
				} else {
					this.$parent.isNav = false;
				}
				// this.scroll = document.documentElement.scrollTop || document.body.scrollTop
			},
			// 回到顶部
			topBackFun() {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				// 实现滚动效果

				let space = 50;
				if (top >= 1000 && top < 2000) {
					space = 50;
				} else if (top >= 2000 && top < 3000) {
					space = 80;
				} else if (top >= 3000 && top < 4000) {
					space = 110;
				} else if (top >= 4000 && top > 6000) {
					space = 150;
				} else if (top >= 6000 && top > 10000) {
					space = 180;
				}

				const timeTop = setInterval(() => {
					document.body.scrollTop =
						document.documentElement.scrollTop =
						top -=
						space;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			},
			linkFun(id) {
				document.querySelector(id).scrollIntoView({
					behavior: "smooth", //带滚动效果
					block: "start", //上边框与顶部空间对齐 默认值
				});
			},

			// 加入清单动画
			addToShopCars(e, el, item) {
				this.shopCarBallEl = e.target;
				this.shopCarBall = true;
				this.addimg = item.thumb;
				this.jionFun(el, item.id);
			},
			// 动画开始
			beforeEnter(el) {
				// 获取元素的大小及其相对于视口的位置
				// const dom = this.shopCarBallEl.getBoundingClientRect()
				const offsetX = 160;
				const offsetY = 100;
				el.style.display = "";
				// y轴是曲直向上的，x轴是蜿蜒的向右的
				el.style.transform = `translate3d(0, -${offsetY}px, 0)`;

				const linnerBall = el.querySelector(".linner_ball");
				linnerBall.style.transform = `translate3d(-${offsetX}px, 0, 0)`;
			},
			enter(el, done) {
				// 触发重绘，来实现动画的移动过程
				this.ss = document.body.offsetHeight;
				el.style.transform = `translate3d(0, 0, 0)`;

				const linnerBall = el.querySelector(".linner_ball");
				linnerBall.style.transform = `translate3d(0, 0, 0)`;
				el.addEventListener("transitionend", done);
			},
			afterEnter(el) {
				this.shopCarBall = false;
				el.style.display = "none";
				this.shopCarNum++;
			},
		},
		// destroyed:function(){
		//     this.$parent.showIndex = 1
		// },
		watch: {
			"$store.state.navList"(newval) {
				this.hot_words = newval.hot_words;
				if (this.hot_words.length > 0) {
					this.search_name = this.hot_words[0].name;
					this.hot_list = this.hot_words[0].lists;
				}
				this.video_cate = newval.video_cate;
			},
			keywords(newval) {
				newval = newval.replace(/[ ]/g, "");
				this.$nextTick(() => {
					this.keywords = newval;
				});
			},
		},
		// 路由跳转之前保存当前滚动条的位置。
		// beforeRouteLeave(to, from, next) {
		//   this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
		//   next();
		//   // if (to.name == 'material' || to.name == 'video') { // 需要缓存的路由name
		//   // 	to.meta.keepAlive = false
		//   // 	next()
		//   // }else{
		//   // 	to.meta.keepAlive = true
		//   // 	next()
		//   // }
		// },
		//使用keepAlive缓存组件状态，页面切换路由会触发 activated 钩子函数。在 activated 中将 之前记录的位置赋值给引起滚动条滚动元素的 scrollTop
		activated() {
			console.log(this.scroll);

			this.$nextTick(() => {
				this.$parent.routerIndex = 1;
				// if (localStorage.getItem("clearAll") == 1) {
				//   this.keywords = "";
				// } else {
				//   setTimeout(() => {
				//     document.documentElement.scrollTop = this.scroll;
				//   }, 0);
				// }
				if (
					localStorage.getItem("total") &&
					localStorage.getItem("access_token")
				) {
					this.total = localStorage.getItem("total");
				}
				localStorage.removeItem("clearAll");
			});
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.hello {
		width: 100%;
		background-color: #f6f6f6;
		/* margin-top: -69px; */
	}

	/* banner */
	.swiper_item {
		display: block;
		width: 100%;
		/* max-height: 510px; */
		position: relative;
		cursor: pointer;
	}

	.swiper_sd img {
		width: 100%;
		/* height: 510px;
        object-fit: none; */
		/* margin-left: calc(50% - 951px); */
	}

	.swiper_item_img {
		position: absolute;
	}

	.swiper_item_img_l {
		width: 450px;
		top: -10px;
		left: 30px;
		animation: swiper1 3s infinite;
	}

	.swiper_item_img_r {
		bottom: -60px;
		right: 200px;
		animation: swiper2 3s infinite;
	}

	@keyframes swiper1 {
		0% {
			top: -10px;
			left: 30px;
		}

		50% {
			top: -20px;
			left: 60px;
		}

		100% {
			top: -10px;
			left: 30px;
		}
	}

	@keyframes swiper2 {
		0% {
			bottom: 0px;
			right: 200px;
		}

		50% {
			bottom: 20px;
			right: 240px;
		}

		100% {
			bottom: 0px;
			right: 200px;
		}
	}

	.swiper-button-prev:after,
	.swiper-button-next:after {
		display: none;
	}

	.swiper-button-prev,
	.swiper-button-next {
		width: 45px;
		height: 45px;
		opacity: 0;
		transition: all 0.3s ease-in-out;
	}

	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next {
		background: url(../assets/images/left@2x.png);
		background-size: 100% 100%;
		left: 64px;
	}

	.swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		background: url(../assets/images/right@2x.png);
		background-size: 100% 100%;
		right: 64px;
	}

	.swiper-container:hover .swiper-button-prev,
	.swiper-container:hover .swiper-button-next {
		opacity: 1;
	}

	.hello>>>.swiper-pagination {
		width: auto;
		left: 50%;
		bottom: 62px;
		transform: translateX(-50%);
		background-color: #282828;
		border-radius: 10px;
		opacity: 0.24;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 8px;
		height: 19px;
		line-height: 19px;
	}

	.hello>>>.swiper-pagination-bullet {
		width: 12px;
		height: 4px;
		border-radius: 4px;
		background: #fff;
		margin: 0 3px;
	}

	.hello>>>.swiper-pagination-bullet-active {
		width: 26px;
		background: #fff;
	}

	/* 搜索 */
	.index_search {
		width: 792px;
		height: 70px;
		background-color: #ffffff;
		box-shadow: 0px 1px 19px 2px rgba(0, 0, 0, 0.07);
		border-radius: 10px;
		margin: -30px auto 24px;
		position: relative;
		z-index: 999;
		padding-right: 160px;
		box-sizing: border-box;
		display: flex;
	}

	.index_search .line {
		display: block;
		width: 1px;
		height: 26px;
		background-color: #eeeeee;
		margin-top: 22px;
	}

	.search_select {
		width: 115px;
		height: 100%;
	}

	.search_select_box {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		padding-left: 12px;
		box-sizing: border-box;
	}

	.search_select_box div {
		margin-right: 6px;
		font-size: 15px;
		letter-spacing: 0px;
		color: #333333;
	}

	.search_select_list {
		width: 100%;
		background-color: #ffffff;
		border-radius: 8px;
		box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
		overflow: hidden;
	}

	.search_select_list div {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
	}

	.search_select_list div:hover {
		background-color: #ebebff;
	}

	.search_input {
		width: calc(100% - 120px);
	}

	.search_input input {
		display: block;
		width: 100%;
		height: 100%;
		font-size: 14px;
		color: #666;
		padding-left: 30px;
		box-sizing: border-box;
		font-size: 16px;
	}

	.search_input input::placeholder {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999999;
	}

	.index_search_button {
		position: absolute;
		top: 3px;
		right: 3px;
		width: 150px;
		height: 64px;
		background-image: linear-gradient(0deg, #5957ff 0%, #9997ffbb 100%);
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.index_search_button img {
		width: 29px;
		height: 31px;
	}

	.hot_search_box {
		height: 16px;
		margin-bottom: 53px;
	}

	.hot_search {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.hot_search>div {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 16px;
		letter-spacing: 0px;
		color: #999999;
		margin-left: 23px;
	}

	.hot_search>div:first-child {
		color: #333333;
		margin-left: 0;
		cursor: default;
	}

	.hot_search_li {
		cursor: pointer;
	}

	.hot_search_li:hover {
		color: #5957ff;
	}

	.entrance_nav {
		margin: 0 -13.33px 45px;
	}

	.entrance_nav_li {
		width: 33.33%;
		/* width: 25%; */
		height: auto;
		box-sizing: border-box;
		cursor: pointer;
		position: relative;
	}

	.entrance_nav_li:nth-child(1) {
		padding: 0 13.33px;
	}

	.entrance_nav_li:nth-child(2) {
		padding-right: 13.33px;
		padding-left: 13.33px;
	}

	.entrance_nav_li:nth-child(3) {
		/* padding: 0 13.33px; */
		padding-right: 13.33px;
		padding-left: 13.33px;
	}

	.entrance_nav_li:nth-child(4) {
		padding: 0 13.33px;
	}

	.entrance_nav_li img {
		width: 100%;
		left: 0;
		top: 0;
		transition: 0.3s;
		object-fit: cover;
	}

	.entrance_nav_li img:hover {
		transform: scale(1.05);
	}

	/* 专题 */
	.project {
		margin: 28px -13.33px 40px;
	}

	.project_li {
		width: 330px;
		height: 300px;
		margin: 0 13.33px 30px;
		padding-top: 15px;
		background: url(../assets/images/bg_tjzt2x.png) no-repeat;
		background-size: 311px auto;
		background-position: center top;
		cursor: pointer;
	}

	.project_li_box {
		position: relative;
		overflow: hidden;
		border-radius: 10px;
		width: 330px;
		height: 300px;
	}

	.project_li_box>img,
	.video_li_box>img,
	.daily_li_box>img {
		/* position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%); */
		width: 100%;
		height: auto;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_hot {
		position: absolute;
		top: 14px;
		left: -10px;
		width: 62px;
		height: 40px;
		background-color: #df0003;
		border-radius: 10px;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 40px;
		text-align: center;
		letter-spacing: 0px;
		color: #ffffff;
		z-index: 101;
		transition: 0.5s;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.project_li:hover .project_li_hot {
		left: 15px;
		padding-left: 0px;
	}

	.project_li_buttom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 45px;
		text-align: left;
		padding: 0 16px;
		box-sizing: border-box;
		line-height: 45px;
		color: #fff;
		font-size: 15px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-webkit-border-radius: 0;
		border-radius: 0;
		background: rgba(0, 0, 0, 0.4);
		opacity: 1;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.project_li_collect {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 14px;
		right: 15px;
		z-index: 100;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		cursor: pointer;
	}

	.project_li_collect img {
		width: 100%;
		height: 100%;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_collect:hover img {
		opacity: 0.8;
	}

	.project_li:hover .project_li_box>img,
	.video_li:hover .video_li_box>img,
	.daily_li:hover .daily_li_box>img {
		transform: scale(1.08);
	}

	.project_li:hover .project_li_buttom {
		opacity: 0;
	}

	.project_li:hover .project_li_mask,
	.project_li:hover .project_li_collect {
		opacity: 1;
	}

	/* 每日推荐 */
	.daily {
		margin: 24px -13.33px 40px;
		display: flex;
		justify-content: center;
		/* flex-wrap: wrap; */
		/* align-items: flex-start; */
	}

	.daily_ul {
		margin: 0 13.33px;
		width: calc(100% / 4 - 26.66px);
	}

	.gradient_position {
		position: relative;
		padding-bottom: 50px;
	}

	.gradient_mask {
		position: absolute;
		z-index: 99;
		bottom: 50px;
		left: 0;
		width: 100%;
		height: 300px;
		background: url(../assets/images/gradual_grey.png) repeat-x;
		pointer-events: none;
	}

	.daily_li {
		width: 100%;
		margin-bottom: 26.66px;
		cursor: pointer;
	}

	.daily_li_box {
		width: 100%;
		/* height: 218px; */
		border-radius: 10px;
		overflow: hidden;
		position: relative;
	}

	.daily_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.daily_li_downLoad {
		position: absolute;
		top: 15px;
		left: 15px;
		width: 72px;
		height: 40px;
		background-color: #5957ff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 14px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}

	.daily_li_downLoad span {
		width: 1px;
		height: 18px;
		background-color: #ffffff;
		opacity: 0.38;
		margin: 0 8px;
	}

	.daily_li_collect,
	.daily_li_shop {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 15px;
		right: 62px;
		z-index: 100;
		opacity: 0;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}

	.daily_li_shop {
		right: 15px;
	}

	.daily_li_collect img,
	.daily_li_shop img {
		width: 100%;
		height: 100%;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.daily_li_collect:hover img,
	.daily_li_shop:hover img {
		opacity: 1;
	}

	.daily_li:hover .daily_li_mask {
		opacity: 1;
	}

	.daily_li:hover .daily_li_downLoad {
		opacity: 1;
	}

	.daily_li:hover .daily_li_collect {
		opacity: 1;
	}

	.daily_li:hover .daily_li_shop {
		opacity: 1;
	}

	/* 视频教程 */
	.video_list {
		margin: 28px -13.33px 45px;
		display: flex;
		justify-content: center;
	}

	.video_li {
		/* margin:0 13.33px 19px;
        cursor: pointer; */
		width: 100%;
		margin-bottom: 26.66px;
		cursor: pointer;
	}

	.video_li_box {
		width: 330px;
		/* height: 218px; */
		border-radius: 10px;
		position: relative;
		overflow: hidden;
	}

	/* .video_li:first-child{
        margin-right: 10px;
    }
    .video_li:first-child .video_li_box{
        width: 690px;
        height: 454px;
    }
    .video_li:first-child .video_li_box>img{
        width: 690px;
        height: 454px;
        object-fit: cover;
    } */

	.video_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.video_li_height {
		position: absolute;
		top: 15px;
		left: 15px;
		width: 72px;
		height: 40px;
		background-color: #5957ff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 14px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}

	.video_li_height span {
		width: 1px;
		height: 18px;
		background-color: #ffffff;
		opacity: 0.38;
		margin: 0 8px;
	}

	.video_li_collect {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 15px;
		right: 15px;
		z-index: 100;
		opacity: 0;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}

	.video_li_collect img {
		width: 100%;
		height: 100%;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.video_li_play {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 101;
		margin-left: -37.5px;
		margin-top: -37px;
		width: 74px;
		height: 75px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		opacity: 0;
	}

	.video_li_collect:hover img {
		opacity: 0.8;
	}

	.video_li:hover .project_li_buttom {
		opacity: 0;
	}

	.video_li:hover .video_li_mask {
		opacity: 1;
	}

	.video_li:hover .video_li_height {
		opacity: 1;
	}

	.video_li:hover .video_li_collect {
		opacity: 1;
	}

	.video_li:hover .video_li_play {
		opacity: 1;
	}

	.cbanner {
		display: block;
		margin-bottom: 78px;
	}

	.cbanner img {
		width: 100%;
	}

	/* 母婴推荐 */
	.mom_list {
		margin: 28px -13.33px 52px;
		display: flex;
	}

	/* 成为会员 */
	.member {
		width: 100%;
		padding: 38px 0 40px;
		background-color: #fff;
	}

	.membervip {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.member_box {
		width: 685px;
		height: 364px;
		text-align: center;
		padding-top: 20px;
		box-sizing: border-box;
		overflow: hidden;
		cursor: pointer;
	}

	.member_box:nth-child(1) {
		/* background: url(../assets/images/videomember2x.png) no-repeat; */
		background-size: 100% 100%;
	}

	.member_box:nth-child(2) {
		/* background: url(../assets/images/bg_scvip2x.png) no-repeat; */
		background-size: 100% 100%;
	}

	.member_box_top {
		height: 196px;
		/* overflow: hidden; */
		position: relative;
	}

	.member_box_top_one {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transition: all 0.3s ease-in-out;
	}

	.member_box_top_two {
		width: 100%;
		position: absolute;
		top: 196px;
		left: 0;
		opacity: 0;
		transition: all 0.3s ease-in-out;
	}

	.member_more .member_box_top_one {
		top: -200px;
		opacity: 0;
	}

	.member_more .member_box_top_two {
		top: 0px;
		opacity: 1;
	}

	.member_box_top img {
		width: auto;

		margin: 0 auto;
	}

	.member_box_top h2 {
		font-size: 30px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 30px;
		letter-spacing: 0px;
		color: #333333;
		margin-bottom: 21px;
	}

	.member_box_top p {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 16px;
		letter-spacing: 0px;
		color: #333333;
		margin-bottom: 22px;
	}

	.member_box_top_one span {
		font-size: 11px;
		color: #666666;
	}

	.member_box .member_box_button {
		width: 120px;
		height: 37px;
		background-color: #333333;
		border-radius: 15px 0px 15px 0px;
		position: relative;
		margin: 0 auto;
		overflow: hidden;
		cursor: pointer;
	}

	.member_box_top_one:hover {
		transform: translateY(-10px);
	}

	.member_box .member_box_button span {
		display: block;
		/* position: absolute;
        left: -50px;
        top: 12px; */
		font-size: 14px;
		line-height: 37px;
		color: #fff;
		/* opacity: 0; */
		transition: all 0.3s ease-in-out;
	}

	.member_box .member_box_button img {
		width: 19px;
		height: 11px;
		position: absolute;
		top: 13px;
		left: 50%;
		margin-left: -9.5px;
		transition: all 0.3s ease-in-out;
	}

	.member_more.member_box_button span {
		left: 18px;
		opacity: 1;
	}

	.member_more.member_box_button img {
		margin-left: 24px;
	}

	.member_box:hover .member_box_button img {
		animation: dou1 0.3s 2;
	}

	@keyframes dou1 {
		0% {
			margin-left: -9.5px;
		}

		50% {
			margin-left: -5px;
		}

		100% {
			margin-left: -9.5px;
		}
	}

	.member_box_top_one_video {
		font-size: 16px;
		line-height: 1;
	}

	.member_box_top_one_video span {
		font-size: 36px;
		font-weight: bold;
		color: #5957ff;
	}

	.member_box_top_one_video span:nth-child(1) {
		font-size: 16px;
		color: #666;
		text-decoration: line-through;
	}

	.member_box_top_one_song {
		font-size: 16px;
		margin-top: 16px;
	}

	.member_box_top_one_song span {
		font-size: 16px;
		color: #5957ff;
		font-weight: bold;
	}

	/* 提供 */
	.provide_list {
		margin: 51px -20px;
	}

	.provide_li {
		width: 255px;
		margin: 0 15px;
		background-color: #ffffff;
		border-radius: 10px;
		padding-top: 28px;
		padding-bottom: 30px;
		text-align: center;
		cursor: pointer;
	}

	.provide_li img {
		width: 40px;
		height: 40px;
		margin: 0 auto 20px;
	}

	.provide_li h5 {
		font-size: 19px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #282828;
		margin-bottom: 12px;
	}

	.provide_li p {
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 16px;
		letter-spacing: 0px;
		color: #666666;
	}

	/* @media screen and (max-width: 1600px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 280px;
            height: 184.4px;
        }
        .project_li{
            background-size: 261px auto;
        }
        .entrance_nav_li{
            width: 280px;
        }
        .video_li:first-child .video_li_box{
            width: 590px;
            height: 389.75px;
        }
        .provide_li{
            width: 270px;
        }
        .member_box{
            width: 580px;
        }
    }
    @media screen and (max-width: 1400px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 240px;
            height: 158px;
        }
        .project_li{
            background-size: 221px auto;
        }
        .entrance_nav_li{
            width: 240px;
        }
        .video_li:first-child .video_li_box{
            width: 510px;
            height: 336.91px;
        }
        .provide_li{
            width: 230px;
        }
        .member_box{
            width: 500px;
        }
    } */

	.sidebar_index {
		left: 5%;
		/* top: 50%; */
		height: 418px;
		bottom: 200px;
		/* transform: translateY(-50%); */
	}

	.alink {
		position: relative;
	}

	.alink_top {
		position: absolute;
		top: -100px;
	}

	.sidebar_r {
		right: 5%;
		bottom: 200px;
		left: auto;
	}

	.bookmall__item .bookmall__item-right-sub2 ::after {
		content: "...";
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.shop_car_ball {
		position: fixed;
		right: 5%;
		bottom: 25%;
		z-index: 9;
		font-size: 16px;
		/* // transition: all .25s cubic-bezier(0.49, -0.29, 0.75, 90.41); */
		/* 贝塞尔曲线 */
		transition: all 1s cubic-bezier(0.6, -0.83, 0.94, 0.71);
	}

	.linner_ball {
		transition: all 1s linear;
		width: 30px;
		height: 20px;
	}

	.linner_ball img {
		width: 100%;
		height: 100%;
	}

	/* 搜索历史 */
	.search_history {
		width: 792px;
		min-height: 50px;
		line-height: 50px;
		background-color: #ffffff;
		box-shadow: -1px 1px 19px 2px rgba(0, 0, 0, 0.07);
		border-radius: 8px;
		margin: 9px auto 0;
		position: absolute;
		top: 70px;
		z-index: 9999;
		padding: 0 76px 0 23px;
		box-sizing: border-box;
	}

	.search_history_list {
		font-size: 20px;
		letter-spacing: 0px;
		color: #999999;
		display: flex;
		flex-flow: row wrap;
	}

	.search_history_list .item {
		color: #666666;
		margin-left: 15px;
		padding-right: 20px;
		cursor: pointer;
		transition: 0.3s;
		display: flex;
		align-items: center;
		position: relative;
	}

	.search_history_list .item img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		display: none !important;
	}

	.search_history_list .item:hover {
		color: #5957ff;
		text-decoration: underline;
	}

	.search_history_list .item:hover img {
		display: block !important;
	}

	.search_history_clear {
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		right: 16px;
		font-size: 14px;
		color: #999999;
		cursor: pointer;
	}

	.search_history_clear img {
		margin-right: 2px;
	}

	.view_more {
		position: absolute;
		bottom: 10px;
		text-align: center;
		z-index: 100;
	}

	.view_more a {
		position: relative;
		z-index: 1;
		display: inline-block;
		width: 200px;
		height: 40px;
		border: 1px #5957ff solid;
		line-height: 40px;
		font-size: 18px;
		color: #5957ff;
		margin-top: 5px;
		cursor: pointer;
		transition: 0.3s;
		border-radius: 5px;
	}

	.view_more a:hover {
		color: #5957ff;
		background: #dcdcff;
	}

	.trsfrom span {
		transform: scale(0.7);
	}
</style>